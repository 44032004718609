const htmlEntities: Record<string, string> = {
    '&quot;': '"',
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&#x2033;': '″',
    '&#34;': '"',
    '&#xba;': 'º',
    '&nbsp;': ' ',
    '&shy;': '­',
    // Add more entities as needed
};

export const containsHtmlEntity = (text: string): boolean => {
    return /&([a-z0-9]+|#[0-9]{1,6}|#x[0-9a-fA-F]{1,6});/gi.test(text);
};

export const decodeHtml = (html: string): string => {
    return html.replace(/&[#\w]+;/g, (match) => {
        return htmlEntities[match] || match;
    });
};
